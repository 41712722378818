
import { useStores } from '@/store/Stores';
import { onBeforeUnmount } from 'vue';

export default {
  components: {},
  setup() {
    const { accountStoreState, headerStore } = useStores();

    onBeforeUnmount(() => {
      headerStore.setTabs('');
    });
    if (accountStoreState.role === 'chief') {
      const tabs = [
        { label: 'tabs.profile', to: '/profile/common' },
        { label: 'tabs.staff', to: '/profile/staff' }
      ];
      headerStore.setTabs(tabs);
    }
  }
};
